import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Assistance For Auto Trading Setup");
    this.metaService.updateTag( { name:'description',content:"Ticket Id: Convert all information into tickets. We prioritize, categorize and assign them to the right Department. For Support, We are here 24X7 to support you."});
    this.metaService.updateTag( { name:'keywords',content:"Trading Expert advisor support, trading software support, automated trading software setup, Trading EA, expert advisor Trading support, automatic trading robot, best algorithmic trading software, auto trading software in Singapore, auto trading software in India, auto trading software in Singapore"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
